import React, { PropsWithChildren } from "react";
import "./NewAssignedIncidentContent.scss";
import { toDateString, useI18n } from "compass-commons";
import OperatorGuideHeader from "../../operatorGuide/header/OperatorGuideHeader";
import { SocketAssignedIncidentDTO } from "../../../model/incident/SocketAssignedIncident";

interface NewAssignedIncidentContentProps {
  incident: SocketAssignedIncidentDTO;
}

const NewAssignedIncidentContent = ({
  incident,
}: PropsWithChildren<NewAssignedIncidentContentProps>): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <>
      <p className="dms-type-hint">
        {translate("incident.newAssignmentFromSupervisor", {
          supervisorName: incident?.assignedByUserName,
        })}
      </p>
      <div
        data-cr="new-assigned-incident-header-content"
        className="new-assigned-incident-div compass-rounded-corner"
      >
        {incident && (
          <OperatorGuideHeader
            incidentHeaderDescription={{
              id: incident.id,
              code: incident.code,
              description: incident.description,
              iconPath: incident.incidentType.iconPath,
              incidentTimestamp: toDateString(
                Date.parse(incident.incidentTimestamp)
              ),
              priority: incident.priority,
              resourceMappingName: incident.resourceMappingName,
              siteName: incident.siteName,
              triggerType: incident.triggerType,
            }}
            className="new-assigned-incident-header"
          />
        )}
      </div>
    </>
  );
};

export default NewAssignedIncidentContent;
