import React, { CSSProperties, useEffect, useState } from "react";
import Spinner from "@msi/cobalt-react/spinner";
import ReactPlayer from "react-player";
import "./mediaModal.module.css";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import ImageMedia from "./ImageMedia";

interface MediaModalProps {
  show: boolean;
  id: string;
  mediaTitle?: string;
  mediaInfo?: string;
  mediaType: string;
  onClose: (Event) => void;
  activeModalId: string;
  mediaFile: any;
  modalStyle?: CSSProperties;
  playerStyle?: CSSProperties;
}

export const VIDEO_TYPES = ["webm", "mp4"];
export const AUDIO_TYPES = ["wav", "mp3"];
export const IMAGE_TYPES = ["jpeg", "jpg", "png"];

const MediaModal = (props: MediaModalProps): JSX.Element => {
  const {
    onClose,
    show,
    id,
    activeModalId,
    mediaFile,
    mediaType,
    mediaInfo,
    mediaTitle,
    modalStyle,
    playerStyle,
  } = props;
  const [isPlaying] = useState(false);
  const { stateService } = useGlobalContext();
  const mediaLoadingSubject = stateService.mediaLoading;
  const [loading, setLoading] = useState(mediaLoadingSubject.value?.isLoading);
  useEffect(() => {
    setLoading(mediaLoadingSubject.value?.isLoading);

    const mediaLoadingSubscription = mediaLoadingSubject.subscribe((val) => {
      if (val != null) {
        setLoading(val?.isLoading);
      }
    });

    return function cleanup() {
      mediaLoadingSubscription.unsubscribe();
    };
  }, [stateService, mediaLoadingSubject]);

  const handleOnClose = (e) => {
    mediaLoadingSubject.next({ isLoading: false });
    onClose(e);
  };

  const VideoMedia = () => {
    let videoUrl = null;
    if (mediaFile != null) {
      const urlCreator = window.URL || window.webkitURL;
      videoUrl = urlCreator.createObjectURL(mediaFile);
    }
    return videoUrl != null ? (
      <ReactPlayer
        className="react-player"
        url={videoUrl}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls
      />
    ) : (
      <></>
    );
  };

  const AudioMedia = () => {
    let audioUrl = null;
    if (mediaFile != null) {
      const urlCreator = window.URL || window.webkitURL;
      audioUrl = urlCreator.createObjectURL(mediaFile);
    }
    return audioUrl != null ? (
      <ReactPlayer
        className="react-player"
        url={audioUrl}
        width="100%"
        height="100%"
        playing={isPlaying}
        controls
      />
    ) : (
      <></>
    );
  };

  const MediaContent = (mediaProps): JSX.Element => {
    const { media } = mediaProps;
    if (VIDEO_TYPES.indexOf(media) >= 0) {
      return <VideoMedia />;
    }

    if (IMAGE_TYPES.indexOf(media) >= 0) {
      return <ImageMedia mediaFile={mediaFile} />;
    }

    if (AUDIO_TYPES.indexOf(media) >= 0) {
      return <AudioMedia />;
    }

    return <></>;
  };

  const ModalHeader = () => {
    const infoDivStyle = {
      marginTop: "10px",
      display: "grid",
      marginLeft: "5px",
    };
    return (
      <div className="modal-header-wrapper">
        <div style={infoDivStyle}>
          <span>{mediaTitle}</span>
          <span>{mediaInfo}</span>
        </div>
        <div
          onClick={handleOnClose}
          onKeyDown={() => {
            console.log();
          }}
          role="button"
          tabIndex={0}
        >
          <span className="close" />
        </div>
      </div>
    );
  };

  return id !== activeModalId || !show ? (
    <></>
  ) : (
    <div style={modalStyle} className="media-modal" id={`modal-${id}`}>
      <ModalHeader />
      <div className="content">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20%",
            }}
          >
            <Spinner size="large" />
          </div>
        ) : (
          <div style={playerStyle} className="player-wrapper">
            <MediaContent media={mediaType} />
          </div>
        )}
      </div>
    </div>
  );
};

MediaModal.defaultProps = {
  mediaTitle: "Media",
  mediaInfo: "",
  modalStyle: "",
  playerStyle: "",
};

export default MediaModal;
