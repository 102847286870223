// eslint-disable-next-line no-shadow
enum ExecutionTaskType {
  ManualTask = "MANUAL_TASK",
  Comment = "COMMENT",
  Attachment = "ATTACHMENT",
  ResourceInteraction = "RESOURCE_INTERACTION",
  GenericInfo = "GENERIC_INFO",
  RecordedVideo = "RECORDED_VIDEO",
  AdditionalActivation = "ADDITIONAL_ACTIVATION",
}

// eslint-disable-next-line import/prefer-default-export
export { ExecutionTaskType };
