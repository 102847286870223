const secondsToMilis = (seconds: number): number => seconds * 1000;

/**
 * Take a function and return a function that is like the initial function.
 * The returned function is invoked _once_ after repeated calls within the `delay`
 * @example
 * const hello = debounce(() => console.log('Hello World'), 300)
 * hello()
 * hello()
 * hello()
 * // 300ms later -> 'Hello World' (once!)
 */
const debounce = (
  callbackFunc: any,
  timeout = 300
): ((...args: any[]) => void) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callbackFunc.apply(this, args);
    }, timeout);
  };
};

const compareDates = (date1, date2) => {
  const date1Parsed = Date.parse(date1);
  const date2Parsed = Date.parse(date2);

  // Check if the dates are valid
  if (Number.isNaN(date1Parsed) || Number.isNaN(date2Parsed)) {
    // Return 0 if either date is invalid
    return 0;
  }

  if (date1Parsed > date2Parsed) {
    return 1;
  }
  if (date1Parsed === date2Parsed) {
    return 0;
  }
  return -1;
};

const sortByProperty =
  (propertyName, isDate = false) =>
  (a, b) => {
    if (!(propertyName in a) || !(propertyName in b)) return 0;

    const aValue = a[propertyName];
    const bValue = b[propertyName];

    if (isDate) {
      return compareDates(aValue, bValue);
    }

    if (typeof aValue === "string" && typeof bValue === "string") {
      return aValue.localeCompare(bValue);
    }
    if (aValue.valueOf() > bValue.valueOf()) return 1;
    if (aValue.valueOf() === bValue.valueOf()) return 0;
    return -1;
  };

// eslint-disable-next-line import/prefer-default-export
export { secondsToMilis, debounce, sortByProperty };
