/**
 * This class is used for tuple type enum like objects.
 */
export default class DocumentContentType {
  static readonly PDF = new DocumentContentType("pdf", "application/pdf");

  // eslint-disable-next-line no-useless-constructor
  private constructor(
    private readonly extension: string,
    public readonly contentType: any
  ) {
    // UTIL CLASS CAN NOT BE INSTANTIATED
  }

  toString() {
    return this.extension;
  }
}
