import React from "react";
import "./errorPage.module.css";

const ErrorPage = () => {
  return (
    <div className="operation-error__page">
      <p>Failed loading camera widgets.</p>
    </div>
  );
};

export default ErrorPage;
