import { SiteFloorPlan } from "compass-commons";
import { ErrorCode } from "compass-widget-library";
import { ResourceMapping } from "../model/resource/ResourceMapping";
import {
  ResourceFunctionality,
  ResourceFunctionalityType,
} from "../model/resource/ResourceFunctionality";
import ResourceMapService from "../services/ResourceMappingService";
import { FunctionalityState } from "../model/resource/FunctionalityState";
import { ResourceCommandAdditionalInfo } from "../model/resource/ResourceCommand";
import { ResourceFunctionalityHolder } from "../model/resource/ResourceFunctionalityHolder";
import { addResourceCameraWidget } from "./openWidgets";
import { getErrorLabelCameraWidget } from "./errorMapperHelper";
import WidgetType from "../model/widget/WidgetType";

const checkResourceFunctionality = (
  resource: ResourceMapping,
  functionalityType: ResourceFunctionalityType
) => {
  if (resource?.mainActionFunctionality) {
    const sendCommandMainFunctionality = resource.functionalities?.find(
      (f) =>
        f.id === resource.mainActionFunctionality &&
        f.type === functionalityType &&
        f.enabled === true
    );
    return sendCommandMainFunctionality !== undefined;
  }
  return false;
};

const performSendCommand = (
  resourceMapping: ResourceMapping,
  startCallback: (res: ResourceMapping) => void = () => null,
  endCallback: (res: ResourceMapping) => void = () => null,
  statusCallback: (
    success: boolean,
    rm: ResourceMapping,
    functionalityId?: string,
    label?: string,
    placeHolders?
  ) => void,
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo,
  functionalityId?: string
) => {
  let canPerformMainActionCommand = false;
  // If functionality id is filled , action comes from Context Menu
  // If not, action is main action and comes from device click
  if (!functionalityId) {
    canPerformMainActionCommand =
      resourceMapping?.mainActionFunctionality &&
      checkResourceFunctionality(
        resourceMapping,
        ResourceFunctionalityType.SEND_COMMAND
      );
  }

  if (canPerformMainActionCommand || functionalityId) {
    startCallback(resourceMapping);
    ResourceMapService.sendCommand(
      resourceMapping.id,
      resourceCommandAdditionalInfo,
      resourceMapping.mainActionFunctionality || functionalityId
    )
      .then(() => {
        endCallback(resourceMapping);
        statusCallback(true, resourceMapping, functionalityId);
      })
      .catch((errorLabel) => {
        endCallback(resourceMapping);
        statusCallback(false, resourceMapping, functionalityId, errorLabel, {
          resourceName: resourceMapping.name,
        });
      });
  }
};

const getMainResourceFunctionality = (
  resource: ResourceMapping
): ResourceFunctionality => {
  if (resource?.mainActionFunctionality) {
    return resource.functionalities?.find(
      (f) => f.id === resource.mainActionFunctionality && f.enabled === true
    );
  }
  return undefined;
};

const getResourceStateColor = (resourceState: FunctionalityState): string => {
  return resourceState && resourceState.color ? resourceState.color : "blue";
};

const getResourceState = (resource: ResourceMapping): FunctionalityState => {
  const stateId = resource?.functionalities.find(
    (rmf) => rmf.id === resource.mainHasStateFunctionality
  )?.currentState;

  if (stateId) {
    return resource?.functionalities
      .find((rmf) => rmf.id === resource.mainHasStateFunctionality)
      ?.hasStateFunctionalityData?.states?.find((s) => s.id === stateId);
  }

  return undefined;
};

const getFriendlyNameAndColor = (
  functionality: ResourceFunctionality
): { name: string; color: string } => {
  switch (functionality.type) {
    case ResourceFunctionalityType.HAS_STATE: {
      const hasState = functionality.hasStateFunctionalityData?.states?.find(
        (o) => o.id === functionality.currentState
      );
      return {
        name: functionality.hasStateFunctionalityData?.name,
        color: hasState?.color,
      };
    }
    case ResourceFunctionalityType.SEND_COMMAND: {
      const sendCommand =
        functionality.sendCommandFunctionalityData?.states?.find(
          (o) => o.id === functionality.currentState
        );
      return {
        name: functionality.sendCommandFunctionalityData?.name,
        color: sendCommand?.color,
      };
    }
    case ResourceFunctionalityType.LIVE_VIDEO_STREAMING: {
      const liveVideo =
        functionality.liveVideoStreamingFunctionalityData?.states?.find(
          (o) => o.id === functionality.currentState
        );
      return {
        name:
          functionality.liveVideoStreamingFunctionalityData?.name ||
          functionality.type,
        color: liveVideo?.color,
      };
    }
    default:
      return { name: functionality.type.toString(), color: "blue" };
  }
};

const getFunctionalities = (rm: ResourceMapping) => {
  return rm.functionalities
    .filter((r) => ResourceFunctionalityType.EVENT_SOURCE !== r.type)
    .map((f) => {
      const { name, color } = getFriendlyNameAndColor(f);
      return {
        id: f.id,
        name,
        stateColor: color,
        type: f.type,
        enabled: f.enabled,
        resourceMappingId: rm.id,
        resourceId: rm.resourceId,
        subsystemId: rm.subsystemId,
        resourceMappingName: rm.name,
        isMainFunctionality: rm.mainActionFunctionality
          ? rm.mainActionFunctionality === f.id
          : false,
      } as ResourceFunctionalityHolder;
    });
};

const executeCommand = (
  type: ResourceFunctionalityType,
  rm: ResourceMapping,
  addResourceLoading: (resourceMappingId: string) => void,
  removeResourceLoading: (resourceMappingId: string) => void,
  statusCallback: (
    success: boolean,
    resourceMapping: ResourceMapping,
    functionalityId?: string,
    label?: string,
    placeHolders?
  ) => void,
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo,
  functionalityId?: string,
  fp?: SiteFloorPlan
) => {
  switch (type) {
    case ResourceFunctionalityType.SEND_COMMAND: {
      performSendCommand(
        rm,
        () => addResourceLoading(rm.id),
        () => removeResourceLoading(rm.id),
        statusCallback,
        resourceCommandAdditionalInfo,
        functionalityId
      );
      break;
    }
    case ResourceFunctionalityType.LIVE_VIDEO_STREAMING: {
      try {
        addResourceCameraWidget(rm, fp, WidgetType.LIVE_CAMERA);
        statusCallback(true, rm, functionalityId);
      } catch (error) {
        statusCallback(
          false,
          rm,
          functionalityId,
          getErrorLabelCameraWidget(ErrorCode.GENERIC)
        );
      }
      break;
    }
    default: {
      // addResourceDisabled(rm.id);
    }
  }
};

const contextMenuClick = (
  event: MouseEvent,
  elementPrefix: string,
  resourceMappingId: string,
  findClosest = false // Usefull when there's more than one tag
) => {
  const fireEvent = new MouseEvent("contextmenu", {
    bubbles: true,
    cancelable: true,
    view: window,
    clientX: event.clientX,
    clientY: event.clientY,
  });

  const element = !findClosest
    ? document.getElementById(`${elementPrefix}-${resourceMappingId}`)
    : (event.target as HTMLElement).closest(
        `[data-cr=${elementPrefix}-${resourceMappingId}]`
      );

  element.dispatchEvent(fireEvent);
};

export {
  checkResourceFunctionality,
  performSendCommand,
  getMainResourceFunctionality,
  getResourceStateColor,
  getResourceState,
  getFunctionalities,
  executeCommand,
  contextMenuClick,
};
