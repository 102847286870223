// General
import React from "react";
import { useI18n } from "compass-commons";
// Styles
import "./OperatorGuideActivationsHeader.scss";
// Contexts
import { Checkbox } from "dms-lib";
import { useGlobalContext } from "../../../../contexts/GlobalContext";

interface OperatorGuideActivationsHeaderProps {
  isChecked: boolean;
  count: number;
}

const OperatorGuideActivationsHeader = ({
  isChecked,
  count,
}: OperatorGuideActivationsHeaderProps): JSX.Element => {
  const { t: translate } = useI18n();
  const { stateService } = useGlobalContext();
  const { incidentAdditionalActivations } = stateService;

  const handleActivations = (_event, checked) => {
    incidentAdditionalActivations.next({
      ...incidentAdditionalActivations.value,
      isAdditionalActivationVisible: checked,
    });
  };

  return (
    <div
      data-cr="operator-guide-activations"
      className="dms-type-label operator-guide-activations"
    >
      <Checkbox
        dataCr="operator-guide-activation-checkbox"
        label={translate("incident.operatorGuideActivationsLabel")}
        labelPlacement="end"
        FormControlClassName="operator-guide-activations__form-control"
        checked={isChecked}
        onChange={handleActivations}
      />
      <span className="operator-guide-activations__count">
        {translate("incident.operatorGuideActivations", {
          count,
        })}
      </span>
    </div>
  );
};

export default React.memo(OperatorGuideActivationsHeader);
