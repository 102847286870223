import React, { useMemo } from "react";
import "./resourceElement.module.css";
import CircleIcon from "@mui/icons-material/CircleRounded";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { createStyles, makeStyles } from "@mui/styles";
import { ResourceIcon } from "dms-lib";
import { ResourceFunctionalityHolder } from "../../../model/resource/ResourceFunctionalityHolder";
import { ICON_SELECTOR_PREFIX } from "../../operatorGuide/execution/taskStep/taskBlocks/ResourceInteractionBlock";

const useStyles = makeStyles(() =>
  createStyles({
    list: {
      padding: "0",
      width: "230px",
    },
    menuItem: {
      display: "flex",
      alignItems: "center",
    },
    textContainer: {
      flex: "1",
      display: "flex",
      alignItems: "center",
    },
    text: {
      flex: "1",
      whiteSpace: "normal",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      inlineSize: "150px",
    },

    iconContainer: {
      display: "flex",
      alignItems: "center",
    },
    icon: {},
  })
);

interface ResourceElementProps {
  id: string;
  disabled?: boolean;
  isLoading?: boolean;
  resourceName?: string;
  resourceIcon?: string;
  onClickHandler?: () => void;
  stateString?: string;
  stateColor?: string;
  withLabel?: boolean;
  classNames?: string[];
  dataCr?: string;
  functionalities?: ResourceFunctionalityHolder[];
  onContextMenuClickHandler?: (
    functionality: ResourceFunctionalityHolder
  ) => void;
  multiple?: boolean; // Used when there'll be more than one resource to interact
}

const ResourceElement = (props: ResourceElementProps): JSX.Element => {
  const {
    id,
    disabled,
    isLoading,
    resourceName,
    resourceIcon,
    onClickHandler,
    stateString,
    stateColor,
    withLabel,
    classNames,
    dataCr,
    functionalities,
    onContextMenuClickHandler,
    multiple,
  } = props;

  const colorToClass = useMemo(
    () => `og-resource-block-icon--${stateColor.toLowerCase()}`,
    [stateColor]
  );

  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const classes = useStyles();

  const handleClose = (functionality: ResourceFunctionalityHolder) => {
    if (functionality) {
      onContextMenuClickHandler(functionality);
    }
    if (contextMenu) {
      setContextMenu(null);
    }
  };

  const handleContextMenu = async (event: React.MouseEvent) => {
    event.preventDefault();

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  return (
    <div
      {...(!multiple ? { id: `og-resource-block-${id}` } : {})}
      className={`og-resource-block ${classNames.join(" ")}`}
      data-cr={dataCr || `resource-interaction-device`}
      onContextMenu={handleContextMenu}
    >
      <div style={{ position: "relative" }}>
        <IconButton
          {...(!multiple ? { id } : {})}
          data-cr={`${ICON_SELECTOR_PREFIX}-${id}`}
          aria-label="save"
          onClick={onClickHandler}
          disabled={disabled}
          title={stateString || resourceName}
          className={`og-resource-block-icon__button ${colorToClass}`}
        >
          <ResourceIcon src={resourceIcon} className="og-resource-block-icon" />
        </IconButton>
        <CircularProgress
          {...(!multiple ? { id: `${id}-spinner` } : {})}
          size={58}
          data-cr={`resource-interaction-device-spinner-${id}`}
          className="og-resource-block-spinner"
          style={{
            visibility: isLoading ? "visible" : "hidden",
          }}
        />
      </div>

      {withLabel && (
        <span className="og-resource-block-name" title={resourceName}>
          {resourceName}
        </span>
      )}
      {functionalities?.length > 0 && (
        <Menu
          id={`context-menu-${functionalities[0].resourceMappingId}`}
          key={`context-menu-${functionalities[0].resourceMappingId}`}
          classes={{ list: classes.list }}
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          disableAutoFocusItem
          anchorPosition={
            contextMenu !== null
              ? {
                  top: contextMenu?.mouseY,
                  left: contextMenu?.mouseX,
                }
              : undefined
          }
        >
          <MenuItem
            className={classes.menuItem}
            style={{
              pointerEvents: "none",
              fontWeight: "bold",
              border: "1px solid rgba(0, 0, 0, 0.06)",
            }}
            selected={false}
          >
            <Typography className={classes.text} variant="inherit">
              {resourceName}
            </Typography>
          </MenuItem>
          {functionalities.map((func) => {
            return (
              <MenuItem
                onClick={() => {
                  handleClose(func);
                }}
                data-cr={`menu-function-${func.id}`}
                className={classes.menuItem}
                key={func.id}
                style={
                  func.isMainFunctionality
                    ? {
                        backgroundColor: "#D9D9D9",
                        border: "1px solid rgba(0, 0, 0, 0.06)",
                      }
                    : { border: "1px solid rgba(0, 0, 0, 0.06)" }
                }
                disabled={!func.enabled}
              >
                <div className={classes.iconContainer}>
                  <ListItemIcon className={classes.icon}>
                    {func.stateColor && (
                      <CircleIcon
                        fontSize="small"
                        sx={{
                          marginTop: "1px",
                          color: func.stateColor,
                        }}
                      />
                    )}
                  </ListItemIcon>
                </div>

                <div className={classes.textContainer}>
                  <div className={classes.text}>{func.name}</div>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </div>
  );
};

ResourceElement.defaultProps = {
  disabled: false,
  isLoading: false,
  resourceName: undefined,
  resourceIcon: undefined,
  onClickHandler: () => null,
  stateString: undefined,
  stateColor: "blue",
  withLabel: true,
  classNames: [],
  dataCr: null,
  onContextMenuClickHandler: () => null,
  functionalities: [],
  multiple: false,
};

export default ResourceElement;
