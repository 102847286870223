import React, { useEffect, useMemo } from "react";
import {
  ResourceInteractionSource,
  useI18n,
  useStatefulArray,
} from "compass-commons";
import { ResourceMappingStateChangeData } from "../../../../../model/notifications/ResourceMappingStateChangeData";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import { ResourceFunctionality } from "../../../../../model/resource/ResourceFunctionality";
import {
  contextMenuClick,
  executeCommand,
  getFunctionalities,
  getMainResourceFunctionality,
  getResourceState,
  getResourceStateColor,
} from "../../../../../helpers/resourceInteractionHelper";
import { FunctionalityState } from "../../../../../model/resource/FunctionalityState";
import { ResourceMapping } from "../../../../../model/resource/ResourceMapping";
import ResourceElement from "../../../../commons/resourceElement/ResourceElement";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { DEFAULT_ICON_IMAGE_PLACEHOLDER } from "../../../../../utils/Settings";
import { ResourceCommandAdditionalInfo } from "../../../../../model/resource/ResourceCommand";
import { ResourceFunctionalityHolder } from "../../../../../model/resource/ResourceFunctionalityHolder";
import {
  getResourceStateString,
  performTaskExecution,
} from "../../../../../helpers/taskHelpers";
import ResourceInteractionBlockSkeleton from "./ResourceInteractionBlockSkeleton";

interface ResourceInteractionBlockProps {
  resources: ResourceMapping[];
  id: string;
  taskState: TaskStepState;
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo;
  executionId: string;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  ignoreResourceInteration?: boolean; // Used to avoid creating a registry inside the og tasks
}

export const ICON_SELECTOR_PREFIX = "resource-interaction-device-button";

/**
 * ResourceInteractionBlock component to display a context tag block but with the Resources Context
 * @param props
 * @constructor
 */
const ResourceInteractionBlock = (props: ResourceInteractionBlockProps) => {
  const {
    resources,
    taskState,
    id,
    resourceCommandAdditionalInfo,
    executionId,
    className,
    isLoading,
    disabled,
    ignoreResourceInteration,
  } = props;
  const { t: translate } = useI18n();
  const {
    add: addLoadingResource,
    remove: removeLoadingResource,
    contains: containsLoadingResource,
  } = useStatefulArray<string>();
  const { stateService } = useGlobalContext();
  const { resourceMappingExecutionStateChange, alertSubject } = stateService;

  const updateResourceColor = (
    rmStateChange: ResourceMappingStateChangeData
  ) => {
    const state = rmStateChange?.functionalities.find(
      (rmf) => rmf.id === rmStateChange.mainHasStateFunctionality
    )?.state;
    const resourcesIcon = document.querySelectorAll<HTMLElement>(`[data-cr=
      ${ICON_SELECTOR_PREFIX}-${rmStateChange.resourceMappingId}]`);
    if (resourcesIcon.length && state) {
      resourcesIcon.forEach((ri) => {
        ri.style.backgroundColor = state.color;
        ri.title = `${translate("resources.state")}: ${state.name}`;
      });
    }
  };

  useEffect(() => {
    const sub = resourceMappingExecutionStateChange.subscribe((value) => {
      if (value) updateResourceColor(value);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [resourceMappingExecutionStateChange]);

  const addIsSendingCommand = (resourceMappingId: string) => {
    addLoadingResource(resourceMappingId);
  };

  const deleteIsSendingCommand = (resourceMappingId: string) => {
    removeLoadingResource(resourceMappingId);
  };

  const commandStatusCallback =
    (
      resource: ResourceMapping,
      functionalityId: string,
      ignoreTaskExecution: boolean
    ) =>
    (success, label, placeHolders) => {
      if (success) {
        if (ignoreTaskExecution) return;
        performTaskExecution(
          stateService,
          id,
          executionId,
          resource,
          functionalityId,
          ResourceInteractionSource.OPERATOR_GUIDE_TASK
        );
      } else {
        alertSubject.next({ title: translate(label, placeHolders) });
      }
    };

  const handleResourceClick = (
    event: MouseEvent,
    resource: ResourceMapping
  ) => {
    const rmFunctionality: ResourceFunctionality =
      getMainResourceFunctionality(resource);

    if (rmFunctionality) {
      executeCommand(
        rmFunctionality.type,
        resource,
        addIsSendingCommand,
        deleteIsSendingCommand,
        commandStatusCallback(
          resource,
          rmFunctionality.id,
          ignoreResourceInteration
        ),
        resourceCommandAdditionalInfo,
        rmFunctionality.id
      );
    } else {
      contextMenuClick(
        event,
        "resource-interaction-device-button",
        resource?.id,
        true
      );
    }
  };

  const getResourceElement = (resource: ResourceMapping): JSX.Element => {
    if (!resource || (!resource.id && !resource.resourceId)) {
      return <></>;
    }

    const spinnerActive = containsLoadingResource(resource.id);

    const disableButton =
      spinnerActive || taskState === TaskStepState.COMPLETED;
    const resourceState: FunctionalityState = getResourceState(resource);

    return (
      <ResourceElement
        multiple
        key={`resource-interaction-${resource.id || resource.resourceId}`}
        id={resource.id || resource.resourceId}
        disabled={disabled ?? disableButton}
        isLoading={spinnerActive}
        resourceName={resource?.name}
        functionalities={getFunctionalities(resource)}
        resourceIcon={resource?.icon || DEFAULT_ICON_IMAGE_PLACEHOLDER}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        onClickHandler={(event) => handleResourceClick(event, resource)}
        stateString={getResourceStateString(translate, resourceState)}
        stateColor={getResourceStateColor(resourceState)}
        onContextMenuClickHandler={(
          resourceFunctionalityHolder: ResourceFunctionalityHolder
        ) => {
          executeCommand(
            resourceFunctionalityHolder.type,
            {
              id: resourceFunctionalityHolder.resourceMappingId,
              resourceId: resourceFunctionalityHolder.resourceId,
              subsystemId: resourceFunctionalityHolder.subsystemId,
              name: resourceFunctionalityHolder?.resourceMappingName,
            } as ResourceMapping,
            addIsSendingCommand,
            deleteIsSendingCommand,
            commandStatusCallback(
              resource,
              resourceFunctionalityHolder.id,
              ignoreResourceInteration
            ),
            resourceCommandAdditionalInfo,
            resourceFunctionalityHolder.id
          );
        }}
      />
    );
  };

  const handleSideEffects = useMemo(
    () =>
      isLoading ? (
        <>
          <ResourceInteractionBlockSkeleton />
        </>
      ) : (
        <i>{translate("resources.notFound")}</i>
      ),
    [isLoading]
  );

  return (
    <div
      key={`resource-interaction-${id}`}
      className={`og-resource-interaction-block${
        className ? ` ${className}` : ""
      }`}
      data-cr="resource-interaction-block"
    >
      {resources?.length > 0
        ? resources?.map((res) => getResourceElement(res))
        : handleSideEffects}
    </div>
  );
};

ResourceInteractionBlock.defaultProps = {
  className: undefined,
  isLoading: false,
  disabled: undefined,
  ignoreResourceInteration: false,
};

export default ResourceInteractionBlock;
