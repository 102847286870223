// In your Redux slice
import { createSlice } from "@reduxjs/toolkit";

const storeSlice = createSlice({
  name: "store",
  initialState: { initialized: false },
  reducers: {
    initializeStore: (state) => {
      state.initialized = true;
    },
  },
});

export const { initializeStore } = storeSlice.actions;

export const selectStoreInitialized = (state) => state.store.initialized;

export default storeSlice.reducer;
