import React from "react";
import PersonIcon from "@mui/icons-material/PersonRounded";
import UnknownPersonIcon from "@mui/icons-material/NoAccountsRounded";
import Typography from "@mui/material/Typography";
import { User } from "compass-commons";
import "./taskUserBadge.module.css";

interface TaskUserBadgeProps {
  user: User;
}

const TaskUserBadge = ({ user }: TaskUserBadgeProps) => {
  const isUserValid = user && user.userId !== "-1";

  return isUserValid ? (
    <div className="task-step-user-info compass-box-shadow">
      <div
        data-cr="task-step-avatar"
        className="task-step-avatar-wrapper"
        title={`${user?.firstName} ${user?.lastName}`}
      >
        <PersonIcon sx={{ fontSize: "2em", color: "#fff", opacity: 0.1 }} />
        <Typography
          component="span"
          style={{
            position: "absolute",
            lineHeight: 1,
            color: "#fff",
            fontSize: "0.75em",
          }}
        >
          {user?.nameInitials}
        </Typography>
      </div>
    </div>
  ) : (
    <div className="task-step-avatar-wrapper" data-cr="task-step-unknown-user">
      <UnknownPersonIcon
        sx={{ fontSize: "2em", color: "#000000", opacity: 0.9 }}
      />
    </div>
  );
};

export default TaskUserBadge;
