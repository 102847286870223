// General
import { useEffect, useRef, useState } from "react";
// Services
import OperatorGuideService from "../services/OperatorGuideService";
// Models
import { OperatorUserHeartbeatResponseDTO } from "../model/heartbeat/OperatorUserHeartbeatResponseDTO";
// Utils
import { secondsToMilis } from "../utils/Utils";

const NETWORK_CHECK_INTERVAL = 5000;
export default function useSendHeartbeat(isLoggedIn: boolean) {
  const [isOffline, setIsOffline] = useState(false);
  const [heartbeatExpired, setHeartbeatExpired] = useState(false);
  const heartbeatTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let isMounted = true;
    let offline = false;
    let nextBeatTime;
    const MAX_HEARTBEAT_DELAY = 5000;
    const operatorUserSendHeartbeat = () => {
      OperatorGuideService.operatorUserSendHeartBeat()
        .then((heartbeatResponse: OperatorUserHeartbeatResponseDTO) => {
          if (!isMounted) {
            return;
          }
          // Handling the incident
          // Send heartbeat at each 10 seconds
          // Heartbeat fails -> Operator is offline
          // Send heartbeat at each 5 seconds
          // If SendHeartbeat with success -> Operator is online -> window.location.reload()
          if (offline) {
            window.location.reload();
            return;
          }
          if (
            nextBeatTime &&
            new Date().getTime() - nextBeatTime > MAX_HEARTBEAT_DELAY
          ) {
            setHeartbeatExpired(true);
          }
          nextBeatTime =
            new Date().getTime() +
            secondsToMilis(heartbeatResponse.nextBeatSeconds);
          setIsOffline(false);
          offline = false;
          heartbeatTimeout.current = setTimeout(
            operatorUserSendHeartbeat,
            secondsToMilis(heartbeatResponse.nextBeatSeconds)
          );
        })
        .catch(() => {
          if (!isMounted) {
            return;
          }
          setIsOffline(true);
          offline = true;
          heartbeatTimeout.current = setTimeout(
            operatorUserSendHeartbeat,
            NETWORK_CHECK_INTERVAL
          );
        });
    };
    if (isLoggedIn) {
      operatorUserSendHeartbeat();
    }

    return () => {
      clearTimeout(heartbeatTimeout.current);
      isMounted = false;
    };
  }, [isLoggedIn]);

  return { isOffline, heartbeatExpired };
}
