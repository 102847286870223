/* eslint-disable no-shadow */

export enum InfoFieldType {
  TEXT = "TEXT",
  PHOTO = "PHOTO",
  PHOTO_ID = "PHOTO_ID",
  DATE = "DATE",
  URL = "URL",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  UNKOWN = "UNKNOWN",
}

export enum GenericInfoCardType {
  DATA = "DATA",
  PHOTO = "PHOTO",
}

export const InfoFieldToGenericInfoMapper = Object.freeze({
  TEXT: "DATA",
  PHOTO: "PHOTO",
  URL: "DATA",
});

export interface InfoFieldDTO {
  type: InfoFieldType;
  label: string;
  value: string;
}
