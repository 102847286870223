// Generic
import React from "react";
// Styles
import "./infoFieldText.module.css";
// Models
import { parseDate } from "compass-commons";
import { InfoFieldTextDTO } from "../../../../../../model/OG/InfoFieldTextDTO";

interface InfoFieldTextProps {
  infoFieldText: InfoFieldTextDTO;
  date?: string;
}

const InfoFieldText = ({
  infoFieldText,
  date,
}: InfoFieldTextProps): JSX.Element => {
  const { label, text } = infoFieldText || {};
  return (
    <div
      key={`operation-info-field-details-${label}`}
      className="operation-info-field__details"
    >
      <div className="operation-info-field__details__header">
        <label htmlFor={label}>{label}:</label>
        {date && <span>{parseDate(date)}</span>}
      </div>
      <p id={label}>{text}</p>
    </div>
  );
};

export default InfoFieldText;
