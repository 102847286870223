import { capitalizeFirstLetter } from "compass-commons";
import React from "react";
import { Button } from "dms-lib";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { OGExecutionTaskBlock } from "../../../../../model/OG/OGExecutionTaskBlock";

interface ConfirmationActionProps {
  taskState: TaskStepState;
  blockType: OGExecutionTaskBlock;
  actionCallBack: (eventId: string) => void;
  selectedAction: string;
}

const ConfirmationAction = (props: ConfirmationActionProps) => {
  const { taskState, blockType, actionCallBack, selectedAction } = props;
  const { negativeAnswer, positiveAnswer } = blockType;
  const getClassName = (actionId: string) => {
    if (TaskStepState.COMPLETED === taskState) {
      if (selectedAction != null && selectedAction === actionId) {
        return " user-selected";
      }
      return " user-not-selected";
    }
    return "";
  };
  return (
    <div className="confirmation-footer" style={{ float: "right" }}>
      <div
        className={`${taskState}${getClassName(positiveAnswer.id)}`}
        data-cr="positive-answer"
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            actionCallBack(positiveAnswer.id);
          }}
          disabled={TaskStepState.COMPLETED === taskState}
          className={getClassName(positiveAnswer.id)}
        >
          <span>{capitalizeFirstLetter(positiveAnswer?.text)}</span>
        </Button>
      </div>
      <div
        className={`${taskState}${getClassName(negativeAnswer.id)}`}
        data-cr="negative-answer"
      >
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => {
            actionCallBack(negativeAnswer.id);
          }}
          disabled={TaskStepState.COMPLETED === taskState}
          className={getClassName(negativeAnswer.id)}
        >
          <span>{capitalizeFirstLetter(negativeAnswer?.text)}</span>
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationAction;
