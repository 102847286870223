import React from "react";
// Styles
import Skeleton from "@mui/material/Skeleton/Skeleton";
import "./resourceInteractionBlockSkeleton.scss";

/**
 * ResourceInteractionBlock component to display a skeleton
 * @param props
 * @constructor
 */
const ResourceInteractionBlockSkeleton = () => {
  return (
    <>
      <Skeleton variant="circular" width={50} height={50} />
      <Skeleton
        variant="rectangular"
        className="operation-resource-interaction__skeleton--rectangular"
      />
    </>
  );
};

export default ResourceInteractionBlockSkeleton;
