import { IncidentState } from "../model/incident/IncidentState";

export const isAssigned = (incidentState: IncidentState) =>
  IncidentState.ASSIGNED === incidentState;

export const isCleared = (incidentState: IncidentState) => {
  return IncidentState.CLEARED === incidentState;
};

export const isActivationCountUpdated = (incidentState: IncidentState) => {
  return IncidentState.ACTIVATION_COUNT_UPDATED === incidentState;
};
