import React from "react";

interface ImageMediaProps {
  mediaFile: any;
}

const ImageMedia = (props: ImageMediaProps): JSX.Element => {
  const { mediaFile } = props;
  let imageUrl = null;
  if (mediaFile != null) {
    const urlCreator = window.URL || window.webkitURL;
    imageUrl = urlCreator.createObjectURL(mediaFile);
  }
  const imageStyle = {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "cover",
    width: "100%",
    height: "100%",
  };
  return imageUrl != null ? (
    <div style={imageStyle} className="image-wrapper" />
  ) : (
    <></>
  );
};

export default ImageMedia;
