// Services
import { BehaviorSubject } from "rxjs";
import OperatorGuideService from "../services/OperatorGuideService";
import { useGlobalContext } from "../contexts/GlobalContext";
import { ACTIVATIONS_INITIAL_VALUE } from "../services/StateService";
// Models
import { SocketAssignedIncidentDTO } from "../model/incident/SocketAssignedIncident";
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";
import {
  IncidentAdditionalActivationDTO,
  OGAdditionalActivations,
} from "../model/OG/IncidentAdditionalActivation";

const useAdditionalActivations = (
  incidentAdditionalActivationsParam?: BehaviorSubject<OGAdditionalActivations>
): {
  getAllActivations: (operatorIncidentId: string) => void;
  handleAdditionalActivations: (
    newAssignedIncidentDTO: SocketAssignedIncidentDTO | AssignedIncidentResponse
  ) => void;
  resetAdditionalActivations: () => void;
} => {
  const { stateService } = useGlobalContext();
  const { incidentAdditionalActivations } = stateService || {
    incidentAdditionalActivations: incidentAdditionalActivationsParam,
  };

  const getAllActivations = (operatorIncidentId: string) => {
    OperatorGuideService.getAdditionalActivations(operatorIncidentId).then(
      (additionalActivations: IncidentAdditionalActivationDTO[]) => {
        incidentAdditionalActivations.next({
          ...incidentAdditionalActivations.value,
          additionalActivations,
        });
      }
    );
  };

  const handleAdditionalActivations = (
    newAssignedIncidentDTO: SocketAssignedIncidentDTO | AssignedIncidentResponse
  ) => {
    if (newAssignedIncidentDTO.additionalActivationsCount <= 0) return;
    getAllActivations(newAssignedIncidentDTO.id);
    incidentAdditionalActivations.next({
      ...incidentAdditionalActivations.value,
      additionalActivationsCount:
        newAssignedIncidentDTO.additionalActivationsCount,
    });
  };

  const resetAdditionalActivations = () => {
    incidentAdditionalActivations.next(ACTIVATIONS_INITIAL_VALUE);
  };

  return {
    getAllActivations,
    handleAdditionalActivations,
    resetAdditionalActivations,
  };
};

// eslint-disable-next-line import/prefer-default-export
export { useAdditionalActivations };
