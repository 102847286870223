import React from "react";
import "./operatorGuideBadge.module.css";
import { useI18n } from "compass-commons";
import { Badge, Tooltip } from "@mui/material";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { IncidentTriggerType } from "../../../model/incident/IncidentTriggerType";

interface OperatorGuideBadgeProps {
  triggerType: IncidentTriggerType;
  children: JSX.Element;
}

const OperatorGuideBadge = ({
  triggerType,
  children,
}: OperatorGuideBadgeProps): JSX.Element => {
  const { t: translate } = useI18n();

  if (triggerType !== "ON-DEMAND") return <>{children}</>;

  return (
    <Badge
      data-cr="operator-guide-header-badge"
      badgeContent={
        <Tooltip
          title={translate("incident.triggerTypeTooltip.onDemand")}
          arrow
        >
          <NewReleasesRoundedIcon />
        </Tooltip>
      }
      overlap="rectangular"
      className="operator-guide-header_badge"
    >
      {children}
    </Badge>
  );
};

export default OperatorGuideBadge;
