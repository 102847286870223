// General
import { useEffect, useRef, useState } from "react";
// Services
import OperatorGuideService from "../services/OperatorGuideService";
// Models
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";

export default function useRequestExecution() {
  const currentAssignedIncident = useRef<AssignedIncidentResponse>(null);
  const [isRequestExecutionInUse, setIsRequestExecutionInUse] = useState(false);
  const requestExecution = useRef<() => void>();
  const requestExecutionTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    let isMounted = true;
    requestExecution.current = () => {
      currentAssignedIncident.current = null;
      setIsRequestExecutionInUse(true);
      OperatorGuideService.getAssignedExecution()
        .then((response: AssignedIncidentResponse) => {
          if (!isMounted) return;
          if (response) {
            currentAssignedIncident.current = response;
          } else {
            requestExecutionTimeout.current = setTimeout(
              requestExecution.current,
              2000
            );
          }
        })
        .catch(() => {
          if (!isMounted) return;
          requestExecutionTimeout.current = setTimeout(
            requestExecution.current,
            2000
          );
        })
        .finally(() => setIsRequestExecutionInUse(false));
    };
    return () => {
      clearTimeout(requestExecutionTimeout.current);
      isMounted = false;
    };
  }, []);

  return {
    requestExecution,
    currentAssignedIncident,
    isRequestExecutionInUse,
  };
}
