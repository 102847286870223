import { SiteFloorPlan } from "compass-commons";
import { ResourceMapping } from "../model/resource/ResourceMapping";
import { ResourceMappingLightDTO } from "../model/incident/ResourceMappingLightDTO";
import { ResourceFunctionalityType } from "../model/resource/ResourceFunctionality";
import WidgetType from "../model/widget/WidgetType";

const openWidget = (details: unknown) => {
  document.dispatchEvent(
    new CustomEvent("addNewWidgetEvent", {
      detail: details,
    })
  );
};
const addResourceCameraWidget = (
  rm: ResourceMapping | ResourceMappingLightDTO,
  fp: SiteFloorPlan = undefined,
  type: WidgetType,
  videoStartAt?: string
) => {
  const resourceMappingId = "id" in rm ? rm.id : rm.resourceMappingId;
  const { subsystemId } = rm; // This id will be assigned as client name to Savitar
  openWidget({
    resource: {
      resourceType: ResourceFunctionalityType.LIVE_VIDEO_STREAMING,
      resourceId: rm.resourceId,
      resourceName: rm.name,
      subsystemId,
      resourceMappingId,
    },
    ...(fp ? { floorPlan: fp.name } : {}),
    widgetType: type,
    videoStartAt,
  });
};

export { openWidget, addResourceCameraWidget };
