// General
import { safelyFormatDate } from "compass-commons";
// Model
import { InfoFieldPhotoShort } from "../model/OG/InfoFieldPhotoDTO";

const hasFullCaption = (infoFieldPhoto: InfoFieldPhotoShort) =>
  !!(infoFieldPhoto?.resourceMappingName && infoFieldPhoto?.mediaTimestamp);

// eslint-disable-next-line
export const buildCaption = (infoFieldPhoto: InfoFieldPhotoShort, delimiter: string) => {
  const dateFormat = infoFieldPhoto?.mediaTimestamp
    ? safelyFormatDate(
        infoFieldPhoto?.mediaTimestamp,
        "yyyy-MM-dd'T'HH:mm:ss.SSSX"
      )
    : "";

  return `${infoFieldPhoto?.resourceMappingName ?? ""}${
    (hasFullCaption(infoFieldPhoto) && delimiter) || ""
  }${dateFormat}`;
};
