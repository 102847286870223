import { HttpClient } from "compass-shared-services";
import { getUrl, MediaInfo, normalizeAPIObject } from "compass-commons";
import { OGExecution } from "../model/OG/OGExecution";
import { OGExecutionComment } from "../model/OG/OGExecutionComment";
import { ProcessEventDTO } from "../model/OG/ProcessEventDTO";
import { CreateCommentRequestDTO } from "../model/OG/CreateCommentRequestDTO";
import { AssignedIncidentResponse } from "../model/incident/AssignedIncidentResponse";
import AssignedExecutionRequestError from "../errors/AssignedExecutionRequestError";
import { OGExecutionResourceInteraction } from "../model/OG/OGExecutionResourceInteraction";
import { OGResourceInteractionRequest } from "../model/OG/OGResourceInteractionRequest";
import { OperatorUserHeartbeatResponseDTO } from "../model/heartbeat/OperatorUserHeartbeatResponseDTO";

import OperatorUserSendHeartbeatRequestError from "../errors/OperatorUserSendHeartbeatRequestError";
import { IncidentAdditionalActivationResponseDTO } from "../model/OG/IncidentAdditionalActivationResponseDTO";
import { IncidentAdditionalActivationDTO } from "../model/OG/IncidentAdditionalActivation";

const {
  OPERATOR_GUIDE_MANAGER_PATH,
  MEDIA_FILE_MANAGER_PATH,
  OPERATION_INCIDENT_MANAGER_PATH,
} = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  EXECUTION: `${OPERATOR_GUIDE_MANAGER_PATH}/execution/(id)`,
  GET_MEDIA_FILE: `${MEDIA_FILE_MANAGER_PATH}/media-files/(mediaFileId)`,
  GET_MEDIA_FILE_CONTENT: `${MEDIA_FILE_MANAGER_PATH}/media-files/(mediaFileId)/content`,
  CLEAR_EXECUTION: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/execution/(executionId)/clear`,
  GET_ASSIGNED_EXECUTION: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/assign`,
  OPERATORS_USERS_HEARTBEAT: `${OPERATION_INCIDENT_MANAGER_PATH}/operation-users/heartbeat`,
  // ACTIVATION
  GET__ADDITIONAL_ACTIVATIONS: `${OPERATION_INCIDENT_MANAGER_PATH}/incidents/(incidentId)/additional-activations`,
};

/**
 * Operator Guide Service where backend service is called
 * for OG operations
 */
export default class OperatorGuideService {
  static async getExecutionById(id: string): Promise<OGExecution> {
    const urlPath = getUrl(URL_PATHS.EXECUTION, { id });
    return httpClient
      .get<OGExecution>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async progressExecution(
    id: string,
    event: ProcessEventDTO
  ): Promise<OGExecution> {
    const urlPath = `${getUrl(URL_PATHS.EXECUTION, { id })}/complete-response`;
    return httpClient
      .put<OGExecution>({ url: urlPath, payload: event })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async addCommentToExecution(
    id: string,
    comment: string
  ): Promise<OGExecutionComment> {
    const urlPath = `${getUrl(URL_PATHS.EXECUTION, { id })}/comments`;
    const ogComment: CreateCommentRequestDTO = {
      comment,
    };
    return httpClient
      .post<OGExecutionComment>({
        url: urlPath,
        payload: ogComment,
      })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async addInteractionToExecution(
    executionId: string,
    interaction: OGResourceInteractionRequest
  ): Promise<OGExecutionResourceInteraction> {
    const urlPath = `${getUrl(URL_PATHS.EXECUTION, {
      id: executionId,
    })}/resource-interaction`;

    return httpClient
      .put<OGExecutionResourceInteraction>({
        url: urlPath,
        payload: interaction,
      })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMediaById(mediaFileId): Promise<MediaInfo> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE, uri);
    return httpClient
      .get<MediaInfo>({
        url: urlPath,
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getMediaContentById(mediaFileId): Promise<any> {
    const uri = {
      mediaFileId,
    };
    const urlPath = getUrl(URL_PATHS.GET_MEDIA_FILE_CONTENT, uri);
    return httpClient
      .get<any>({
        url: urlPath,
        config: {
          responseType: "blob",
        },
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async clearIncident(
    incidentId: string,
    executionId: string,
    requestDTO?: any /** TODO This will be changed according to BE implementation */
  ): Promise<OGExecution> {
    const uri = {
      incidentId,
      executionId,
    };
    const urlPath = getUrl(URL_PATHS.CLEAR_EXECUTION, uri);
    return httpClient
      .delete<OGExecution>({ url: urlPath, payload: requestDTO })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getAssignedExecution(): Promise<AssignedIncidentResponse> {
    const urlPath = `${URL_PATHS.GET_ASSIGNED_EXECUTION}`;
    return httpClient
      .post<AssignedIncidentResponse>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch((error) => {
        throw new AssignedExecutionRequestError(error, error?.errorMessage);
      });
  }

  static async operatorUserSendHeartBeat(): Promise<OperatorUserHeartbeatResponseDTO> {
    const urlPath = `${URL_PATHS.OPERATORS_USERS_HEARTBEAT}`;
    return httpClient
      .post<OperatorUserHeartbeatResponseDTO>({ url: urlPath })
      .then((response) => {
        return normalizeAPIObject(response);
      })
      .catch((error) => {
        throw new OperatorUserSendHeartbeatRequestError(
          error,
          error?.errorMessage
        );
      });
  }

  // ADDITIONAL ACTIVATIONS

  static async getAdditionalActivations(
    incidentId: string,
    pageParameters?: {
      pageIndex: number;
      pageSize: number;
    }
  ): Promise<IncidentAdditionalActivationDTO[]> {
    const uri = {
      incidentId,
    };
    const urlPath = getUrl(URL_PATHS.GET__ADDITIONAL_ACTIVATIONS, uri);
    return httpClient
      .get<IncidentAdditionalActivationResponseDTO>({
        url: urlPath,
        config: {
          params: {
            pageIndex: pageParameters?.pageIndex,
            pageSize: pageParameters?.pageSize,
          },
        },
      })
      .then((response) => {
        return normalizeAPIObject(response.additionalActivations);
      })
      .catch((error) => {
        throw Error(error);
      });
  }
}
