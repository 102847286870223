import React, { useState, useEffect, useRef } from "react";
import "../operatorGuide.module.css";
import { OGExecution } from "../../../model/OG/OGExecution";
import { mapTask } from "../../../helpers/taskHelpers";
import { TaskStepState } from "../../../model/OG/TaskStepState";
import { OGExecutionTask } from "../../../model/OG/OGExecutionTask";
import { ResourceCommandAdditionalInfo } from "../../../model/resource/ResourceCommand";
import OperatorGuideExecutionHeader from "./header/OperatorGuideExecutionHeader";
import { useGlobalContext } from "../../../contexts/GlobalContext";
import {
  IncidentAADTOToOGExecutiondAAInfoLists,
  isTaskOutsideOGConfiguration,
} from "../../../helpers/oGExecutionHelper";
import { sortByProperty } from "../../../utils/Utils";
import { OGAdditionalActivations } from "../../../model/OG/IncidentAdditionalActivation";
import { ResourceMappingStateChangeData } from "../../../model/notifications/ResourceMappingStateChangeData";

interface OperatorGuideExecutionProps {
  execution: OGExecution;
  resourceCommandAdditionalInfo: ResourceCommandAdditionalInfo;
}

const { RESOURCE_MAPPING_STATE_CHANGE_HUB } = appConfig;

const OperatorGuideExecution = (
  props: OperatorGuideExecutionProps
): JSX.Element => {
  const { execution, resourceCommandAdditionalInfo } = props;
  const { stateService, listenerService } = useGlobalContext();
  const { incidentAdditionalActivations, resourceMappingExecutionStateChange } =
    stateService;
  const executionListRef = useRef(null);
  const executionListLastItemRef = useRef(null);

  const [isScrolledUp, setIsScrolledUp] = useState(false);

  const [additionalActivations, setAdditionalActivations] =
    useState<OGAdditionalActivations>(incidentAdditionalActivations.value);

  // 2 Types of Completed Tasks (For now):
  // - All the steps completed by the user in the OG Guide
  // - Activations that comes from the system
  const getCompletedTasks = (): JSX.Element[] => {
    const activationsAsTask =
      (additionalActivations.isAdditionalActivationVisible &&
        IncidentAADTOToOGExecutiondAAInfoLists(
          additionalActivations.additionalActivations
        )) ||
      [];

    const fullCompletedTask = execution.registryResponse.stepsList
      .concat(activationsAsTask)
      .sort(sortByProperty("clearedTimeStamp", true));

    const tasks: JSX.Element[] = [];

    fullCompletedTask?.forEach((step, index) => {
      const className =
        step.user || isTaskOutsideOGConfiguration(step?.type)
          ? "operator-guide-completed-operator"
          : "operator-guide-completed-system";

      tasks.push(
        <div
          key={`map-task-${index}-${step.id || tasks.length + 1}`}
          className={`${className}  compass-rounded-corner`}
        >
          {mapTask(
            index,
            execution.registryResponse.id,
            step,
            TaskStepState.COMPLETED,
            resourceCommandAdditionalInfo
          )}
        </div>
      );
    });

    tasks.push();
    return tasks;
  };

  const getCurrentTask = (): JSX.Element => {
    if (execution.engineTaskResponseDTO?.taskTypeEngineList?.length > 0) {
      const currentTask =
        execution.engineTaskResponseDTO.taskTypeEngineList.find(
          (task: OGExecutionTask) => task.current === true
        );
      if (currentTask) {
        return (
          <div className="operator-guide-current compass-rounded-corner">
            {mapTask(
              currentTask.id,
              execution.registryResponse.id,
              currentTask,
              TaskStepState.CURRENT,
              resourceCommandAdditionalInfo
            )}
          </div>
        );
      }
    }
    return <></>;
  };

  const handleScroll = () => {
    // Get the current scroll position
    const currentPos = executionListRef.current.scrollTop;
    const { clientHeight, scrollHeight } = executionListRef.current;
    if (currentPos + clientHeight < scrollHeight) {
      setIsScrolledUp(true);
    } else {
      setIsScrolledUp(false);
    }
  };

  useEffect(() => {
    const sub = incidentAdditionalActivations.subscribe((value) => {
      setAdditionalActivations(value);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [incidentAdditionalActivations]);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    if (isScrolledUp) return;

    executionListLastItemRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [additionalActivations, isScrolledUp]);

  /**
   * Improve the listener function. At each element added we are listening again.
   * And if we remove one we are ending the connection to all of them.
   */
  useEffect(() => {
    listenerService.listen(
      RESOURCE_MAPPING_STATE_CHANGE_HUB,
      (msg) => {
        const resourceMappingStateChangeData: ResourceMappingStateChangeData =
          JSON.parse(msg);
        resourceMappingExecutionStateChange.next(
          resourceMappingStateChangeData
        );
      },
      "execution"
    );

    return () => {
      listenerService.stopListening(
        RESOURCE_MAPPING_STATE_CHANGE_HUB,
        "execution"
      );
    };
  }, [listenerService]);

  return (
    <div className="operator-guide-execution">
      <OperatorGuideExecutionHeader
        additionalActivationsHeader={{
          isChecked: additionalActivations.isAdditionalActivationVisible,
          count: additionalActivations.additionalActivationsCount,
        }}
      />
      <div
        className="operator-guide-execution-list"
        onScroll={handleScroll}
        ref={executionListRef}
      >
        {getCompletedTasks()}
        {getCurrentTask()}
        {/* {getNextTask()}   NO NEXT TASK FOR NOW */}
        <div id="scroll-to" ref={executionListLastItemRef} />
      </div>
    </div>
  );
};

export default OperatorGuideExecution;
