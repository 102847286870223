import React, { PropsWithChildren, useEffect } from "react";
import { Provider, useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { cameraReducer } from "compass-widget-library";
import storeReducer, { initializeStore } from "./storeSlice";

const store = configureStore({
  reducer: {
    cameras: cameraReducer,
    store: storeReducer,
  },
});

const InitializeStore = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeStore());
  }, [dispatch]);

  return <>{children}</>;
};

const StoreProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <Provider store={store}>
      <InitializeStore>{children}</InitializeStore>
    </Provider>
  );
};

export default StoreProvider;
