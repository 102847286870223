// Generic
import React, { useEffect, useMemo, useState, useRef } from "react";
// Styles
import { BadgeRoundedIcon } from "dms-lib";
import "./genericInfoCardTask.module.css";
// Components
import { User } from "compass-commons";
import InfoFieldPhoto from "./infoFieldsData/InfoFieldPhoto";
import InfoFieldText from "./infoFieldsData/InfoFieldText";
import InfoFieldUrl from "./infoFieldsData/InfoFieldUrl";
// Models
import {
  InfoFieldDTO,
  InfoFieldType,
  GenericInfoCardType,
  InfoFieldToGenericInfoMapper,
} from "../../../../../model/OG/InfoFieldDTO";
import {
  hasMedia,
  InfoFieldPhotoDTO,
  InfoFieldPhotoShort,
} from "../../../../../model/OG/InfoFieldPhotoDTO";
import { InfoFieldTextDTO } from "../../../../../model/OG/InfoFieldTextDTO";
import { InfoFieldUrlDTO } from "../../../../../model/OG/InfoFieldUrlDTO";
import ImageGallery from "../../../../commons/imageGallery/ImageGallery";
import TaskUserBadge from "../taskUserBadge/TaskUserBadge";

interface GenericInfoCardTaskProps<T extends InfoFieldDTO> {
  infoFieldDTOs: T[];
  user?: User;
  clearedTime?: string;
}

const GenericInfoCardTask = <T extends InfoFieldDTO>({
  infoFieldDTOs,
  user,
  clearedTime,
}: GenericInfoCardTaskProps<T>): JSX.Element => {
  const [galleryFullScreenMode, setGalleryFullScreenMode] =
    useState<boolean>(false);
  // Use to keep tracking what file id we should fetch
  const [fullScreenFileShort, setFullScreenFileShort] = useState(null);
  const imageWidthContainerRef = useRef(null);

  const [mediaData, setMediaData] = useState<{
    [key in GenericInfoCardType]: JSX.Element[];
  }>({
    [GenericInfoCardType.PHOTO]: [],
    [GenericInfoCardType.DATA]: [],
  });

  // Mapping from InfoField data to Component
  const InfoFieldToComponent = {
    [InfoFieldType.PHOTO]: (infoPhoto: InfoFieldDTO) => (
      <InfoFieldPhoto
        infoFieldPhoto={infoPhoto as InfoFieldPhotoDTO}
        onImgClick={(infoFieldPhotoShort: InfoFieldPhotoShort) => {
          setFullScreenFileShort(infoFieldPhotoShort);
          setGalleryFullScreenMode(true);
        }}
      />
    ),
    [InfoFieldType.TEXT]: (infoText: InfoFieldDTO, date?: string) => (
      <InfoFieldText infoFieldText={infoText as InfoFieldTextDTO} date={date} />
    ),
    [InfoFieldType.URL]: (infoUrl: InfoFieldDTO) => (
      <InfoFieldUrl infoFieldUrl={infoUrl as InfoFieldUrlDTO} />
    ),
  };

  const getPhotoData = useMemo(() => mediaData.PHOTO, [mediaData.PHOTO]);
  const getInfoData = useMemo(() => mediaData.DATA, [mediaData.DATA]);

  const prepareMediaData = () => {
    const tempMediaData = {
      [GenericInfoCardType.PHOTO]: [],
      [GenericInfoCardType.DATA]: [],
    };

    const tempFullScreenMedia = [];
    let isDateAdded = false;
    infoFieldDTOs.forEach((infof: InfoFieldDTO) => {
      if (hasMedia(infof))
        tempFullScreenMedia.push({
          fileId: infof.fileId,
          mediaType: infof.mediaFileDTO?.thumbnail?.mediaType,
          content: infof.mediaFileDTO?.thumbnail?.content,
        });
      tempMediaData[InfoFieldToGenericInfoMapper[infof.type]].push(
        InfoFieldToComponent[infof.type](infof, !isDateAdded && clearedTime)
      );
      if (infof.type === InfoFieldType.TEXT && !isDateAdded) isDateAdded = true;
    });

    setMediaData(tempMediaData);
  };

  useEffect(() => {
    prepareMediaData();
  }, []);

  const isGallery = (!getInfoData.length && "gallery") || "list";
  return (
    <>
      <div className="operation-geninfo-card__wrapper">
        <div className="operation-geninfo-card__container">
          <div className="operation-geninfo-card__icon">
            {user ? <TaskUserBadge user={user} /> : <BadgeRoundedIcon />}
          </div>
          {(getPhotoData.length && (
            <div
              ref={imageWidthContainerRef}
              className={`operation-geninfo-card__media operation-geninfo-card__images--${isGallery}`}
            >
              {...getPhotoData}
            </div>
          )) ||
            null}
          {(getInfoData.length && (
            <div className="operation-geninfo-card__info">{...getInfoData}</div>
          )) ||
            null}
        </div>
      </div>
      {galleryFullScreenMode && (
        <ImageGallery
          isOpen={galleryFullScreenMode}
          mediaFileShort={fullScreenFileShort}
          onClose={() => setGalleryFullScreenMode(false)}
        />
      )}
    </>
  );
};

export default GenericInfoCardTask;
