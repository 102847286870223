// eslint-disable-next-line no-shadow
enum BlockTypes {
  TextField = "SINGLE_TEXT_FIELD",
  Confirmation = "CONFIRMATION",
  MultiOption = "MULTI_OPTION",
  Button = "BUTTON",
  Dropdown = "DROPDOWN",
  Comment = "COMMENT",
  ContextTag = "CONTEXT_TAG",
}

// eslint-disable-next-line import/prefer-default-export
export { BlockTypes };
