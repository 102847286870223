// Generic
import React from "react";
import {
  parseDate,
  ResourceInteractionSource,
  SiteFloorPlan,
  useI18n,
} from "compass-commons";
// Styles
import VideocamIcon from "@mui/icons-material/VideocamRounded";
import "./recordedVideoTask.module.css";

import { ErrorCode } from "compass-widget-library";
import { useGlobalContext } from "../../../../../contexts/GlobalContext";
import { ResourceFunctionality } from "../../../../../model/resource/ResourceFunctionality";
import {
  contextMenuClick,
  getFunctionalities,
  getMainResourceFunctionality,
  getResourceState,
  getResourceStateColor,
} from "../../../../../helpers/resourceInteractionHelper";
import { FunctionalityState } from "../../../../../model/resource/FunctionalityState";
import { ResourceMapping } from "../../../../../model/resource/ResourceMapping";
import { OGExecutionTask } from "../../../../../model/OG/OGExecutionTask";
import ResourceElement from "../../../../commons/resourceElement/ResourceElement";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { DEFAULT_ICON_IMAGE_PLACEHOLDER } from "../../../../../utils/Settings";
import { ResourceFunctionalityHolder } from "../../../../../model/resource/ResourceFunctionalityHolder";
import {
  getDateInfo,
  getResourceStateString,
  performTaskExecution,
} from "../../../../../helpers/taskHelpers";
import { addResourceCameraWidget } from "../../../../../helpers/openWidgets";
import { getErrorLabelCameraWidget } from "../../../../../helpers/errorMapperHelper";
import WidgetType from "../../../../../model/widget/WidgetType";

interface RecordedVideoTaskBlockProps {
  videoStartTime: string;
  resources: ResourceMapping[];
  task: OGExecutionTask;
  taskState: TaskStepState;
  executionId: string;
}

/**
 * ResourceInteractionBlock component to display a context tag block but with the Resources Context
 * @param props
 * @constructor
 */
const RecordedVideoTaskBlock = (props: RecordedVideoTaskBlockProps) => {
  const { resources, videoStartTime, taskState, task, executionId } = props;
  const { t: translate } = useI18n();
  const { stateService } = useGlobalContext();
  const { alertSubject } = stateService;

  const commandStatusCallback =
    (resource: ResourceMapping, functionalityId: string) =>
    (success, label, placeHolders) => {
      if (success) {
        performTaskExecution(
          stateService,
          task.id,
          executionId,
          resource,
          functionalityId,
          ResourceInteractionSource.RELATED_RESOURCE
        );
      } else {
        alertSubject.next({ title: translate(label, placeHolders) });
      }
    };

  const addToWidget = (
    rm: ResourceMapping,
    startAt: string,
    statusCallback: (success: boolean, label?: string, placeHolders?) => void,
    fp?: SiteFloorPlan
  ) => {
    try {
      addResourceCameraWidget(rm, fp, WidgetType.RECORDED_VIDEO, startAt);
      statusCallback(true);
    } catch (error) {
      statusCallback(false, getErrorLabelCameraWidget(ErrorCode.GENERIC));
    }
  };

  const handleResourceClick = (
    event: MouseEvent,
    resource: ResourceMapping
  ) => {
    const rmFunctionality: ResourceFunctionality =
      getMainResourceFunctionality(resource);

    if (rmFunctionality) {
      addToWidget(
        resource,
        videoStartTime,
        commandStatusCallback(resource, rmFunctionality.id)
      );
    } else {
      contextMenuClick(event, "recorded-video", resource.id);
    }
  };

  const getResourceElement = (resource: ResourceMapping): JSX.Element => {
    if (!resource || (!resource.id && !resource.resourceId)) {
      return <></>;
    }

    const resourceState: FunctionalityState = getResourceState(resource);

    return (
      <ResourceElement
        key={`recorded-video-${resource.id || resource.resourceId}`}
        id={`recorded-video-${resource.id || resource.resourceId}`}
        resourceName={resource?.name}
        functionalities={getFunctionalities(resource)}
        resourceIcon={resource?.icon || DEFAULT_ICON_IMAGE_PLACEHOLDER}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        onClickHandler={(event) => handleResourceClick(event, resource)}
        stateString={getResourceStateString(translate, resourceState)}
        stateColor={getResourceStateColor(resourceState)}
        onContextMenuClickHandler={(
          resourceFunctionalityHolder: ResourceFunctionalityHolder
        ) => {
          addToWidget(
            {
              id: resourceFunctionalityHolder.resourceMappingId,
              resourceId: resourceFunctionalityHolder.resourceId,
              subsystemId: resourceFunctionalityHolder.subsystemId,
              name: resourceFunctionalityHolder?.resourceMappingName,
            } as ResourceMapping,
            videoStartTime,
            commandStatusCallback(resource, resourceFunctionalityHolder.id)
          );
        }}
      />
    );
  };

  return (
    <>
      <div
        className="operation-recorded-video__wrapper"
        data-cr="recorded-video-wrapper"
      >
        <div className="operation-recorded-video__container">
          <div className="operation-recorded-video__icon">
            <VideocamIcon />
          </div>
          <div className="operation-recorded-video__info">
            <div className="info-header">
              {videoStartTime && (
                <span className="wrap-text">
                  {`${translate(
                    "incident.operatorGuide.recordedVideo.videoCapturedMessage"
                  )} ${parseDate(videoStartTime)}`}
                </span>
              )}
              <span className="date-info">
                {getDateInfo(taskState, task) && (
                  <span
                    data-cr="task-step-date-completed"
                    style={{ fontSize: "12px", fontWeight: "bold" }}
                  >
                    {getDateInfo(taskState, task)}
                  </span>
                )}
              </span>
            </div>
            <div className="info-resources">
              {resources?.length > 0 ? (
                resources?.map((res) => getResourceElement(res))
              ) : (
                <i>{translate("resources.notFound")}</i>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecordedVideoTaskBlock;
