// Generic
import React, { useState, useEffect, useRef } from "react";
import {
  MediaFileStatus,
  MediaInfo,
  isMediaAvailable,
  isMediaWaiting,
} from "compass-commons";
// Styles
import "./infoFieldPhoto.module.css";
// Components
import { Image } from "dms-lib";
// Models
import {
  InfoFieldPhotoDTO,
  InfoFieldPhotoShort,
} from "../../../../../../model/OG/InfoFieldPhotoDTO";
import GetMediaFileError from "../../../../../../errors/GetMediaFileError";
// Services
import OperatorGuideService from "../../../../../../services/OperatorGuideService";
// Helpers
import { buildCaption } from "../../../../../../helpers/captionHelper";

interface InfoFieldPhotoProps {
  infoFieldPhoto: InfoFieldPhotoDTO;
  onImgClick?: (infoFieldPhotoShort: InfoFieldPhotoShort) => void;
}

const InfoFieldPhoto = ({
  infoFieldPhoto,
  onImgClick,
}: InfoFieldPhotoProps): JSX.Element => {
  const [imageData, setImageData] = useState<InfoFieldPhotoDTO>(infoFieldPhoto);

  // Create a media file Polling for each image
  // Purpose: Check if image is still being proccessed
  // Only trigger setTimeout if image is still being prepared
  const imagePollingTimeout = useRef(null);

  const mediaPollingCondition = (mediaFile: MediaInfo) =>
    !mediaFile || isMediaWaiting(mediaFile.mediaFileStatus);

  const startPolling = (fileId: string, mediaFile: MediaInfo) =>
    fileId && mediaPollingCondition(mediaFile);

  const getMedia = async (): Promise<MediaInfo> => {
    try {
      const result = await OperatorGuideService.getMediaById(imageData.fileId);
      return result;
    } catch (error) {
      clearTimeout(imagePollingTimeout?.current);
      throw new GetMediaFileError(error);
    }
  };

  const mediaPolling = async () => {
    let mediaFile: MediaInfo;
    let pollingError = false;
    try {
      mediaFile = await getMedia();

      if (mediaPollingCondition(mediaFile)) {
        imagePollingTimeout.current = setTimeout(mediaPolling, 1000);
        return;
      }
    } catch (error) {
      pollingError = true; // Importanto flag to allert finally that an error occurred
    } finally {
      // Important to keep track of what was the previous state.
      // When polling is possible that mediaFile is still null therefore we need to keep polling.
      setImageData((prevState: InfoFieldPhotoDTO) => ({
        ...prevState,
        mediaFileDTO: {
          ...(mediaFile || prevState?.mediaFileDTO),
          thumbnail: {
            ...(mediaFile?.thumbnail || prevState?.mediaFileDTO?.thumbnail),
          },
          mediaFileStatus:
            (pollingError && MediaFileStatus.UPLOAD_FAILED) ||
            mediaFile?.mediaFileStatus ||
            prevState?.mediaFileDTO?.mediaFileStatus,
        },
      }));
    }
  };

  // Conditions to handle different use cases
  const showMedia =
    imageData.fileId &&
    isMediaAvailable(imageData.mediaFileDTO?.mediaFileStatus);

  const isPolling = startPolling(imageData.fileId, imageData.mediaFileDTO);
  useEffect(() => {
    if (startPolling(imageData.fileId, imageData.mediaFileDTO)) mediaPolling();

    return () => {
      clearTimeout(imagePollingTimeout?.current);
    };
  }, []);

  return (
    <Image
      id={imageData.fileId}
      src={`data:${imageData.mediaFileDTO?.thumbnail?.mediaType};base64,${imageData.mediaFileDTO?.thumbnail?.content}`}
      alt="addition info"
      imageClassName="operation-info-field__photo"
      hasError={!showMedia}
      isLoading={isPolling}
      caption={buildCaption(
        {
          fileId: infoFieldPhoto.fileId,
          resourceMappingName: imageData.resourceMapping?.name,
          mediaTimestamp: imageData.mediaFileDTO?.mediaTimestamp,
        },
        " | "
      )}
      onClick={() =>
        onImgClick({
          fileId: imageData.fileId,
          resourceMappingName: imageData.resourceMapping?.name,
          mediaTimestamp: imageData.mediaFileDTO?.mediaTimestamp,
        })
      }
    />
  );
};

InfoFieldPhoto.defaultProps = {
  onImgClick: undefined,
};

export default InfoFieldPhoto;
