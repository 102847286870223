import React, { useEffect, useState } from "react";
// general
import { getStringFromDate } from "compass-commons";
import { BehaviorSubject } from "rxjs";
// components
import CheckCircleIcon from "@mui/icons-material/CheckCircleRounded";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { IncidentIcon } from "dms-lib";
// models
import { IClearIncident } from "./model/IClearIncidentAlert";
// css
import "./ClearIncidentAlert.module.css";

interface ClearIncidentAlertProps {
  clearIncidentAlertSubject: BehaviorSubject<IClearIncident>;
}

const ClearIncidentAlert = ({
  clearIncidentAlertSubject,
}: ClearIncidentAlertProps) => {
  const [notification, setNotification] = useState<IClearIncident>(
    clearIncidentAlertSubject.value
  );

  useEffect(() => {
    const sub = clearIncidentAlertSubject.subscribe(setNotification);
    return () => {
      sub.unsubscribe();
    };
  }, [clearIncidentAlertSubject]);

  const handleClose = (_: any, reason?: string) => {
    if (reason === "clickaway") return;
    clearIncidentAlertSubject.next(null);
  };

  const anchorSnackbar = { vertical: "top", horizontal: "right" };
  return (
    <Snackbar
      open={!!notification}
      anchorOrigin={anchorSnackbar as SnackbarOrigin}
      className="clear-incident-alert"
      data-cr="clear-incident-alert"
      autoHideDuration={6000}
      onClose={handleClose}
      message={
        <>
          <div className="clear-incident-alert__message-wrapper">
            {notification?.icon && (
              <IncidentIcon
                src={notification?.icon}
                priority={notification?.priority}
                size="small"
                className="incident-fit-icon"
              />
            )}
            <span
              className="clear-incident-alert__message"
              id="client-snackbar"
            >
              {notification?.message}
            </span>
          </div>
          <div className="clear-incident-alert__date-wrapper">
            <div className="clear-incident-alert__status-available">
              <CheckCircleIcon />
            </div>
            <span className="clear-incident-alert__date" id="client-snackbar">
              {getStringFromDate(new Date())}
            </span>
          </div>
        </>
      }
    />
  );
};

export default ClearIncidentAlert;
