import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import ResourceMappingService from "../../../../../services/ResourceMappingService";
import DatabaseSearchResponseDTO from "../../../../../model/databaseSearch/DatabaseSearchResponseDTO";

interface DropdownBlockProps {
  dataCr?: string;
  placeholder?: string;
  tags: string[];
  completed: boolean;
}

const DropdownBlock = (props: DropdownBlockProps): React.JSX.Element => {
  const { dataCr, placeholder, tags, completed } = props;

  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [lastQuery, setLastQuery] = useState<string | null>(inputValue);
  const [searching, setSearching] = useState(completed);
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);

  const fetchData = async () => {
    if (lastQuery === inputValue || !inputValue) {
      return;
    }
    setLastQuery(inputValue);
    setSearching(true);
    setOpenDropdown(true);
    setOptions([]); // Clear the options

    ResourceMappingService.databaseSearch(tags, inputValue, 1, "some-id") // TODO : 'resourceTriggerRMId' must be defined and tested in the following task.
      .then((response: DatabaseSearchResponseDTO) => {
        const { subsystemsResults } = response;

        const newOptions = subsystemsResults.reduce(
          (accumulator, subsystemResult) => {
            accumulator.push(
              ...subsystemResult.results.map((result) => {
                // TODO: Change this to include all info fields
                return result.infoFields.find(
                  (infoField) => infoField.label === "Name"
                ).value;
              })
            );

            return accumulator;
          },
          []
        );
        setOptions(newOptions);
      })
      .finally(() => setSearching(false));
  };

  // Debounce the search
  useEffect(() => {
    let searchSetTimeout;
    if (inputValue) {
      searchSetTimeout = setTimeout(fetchData, 750);
    } else {
      setOptions([]);
      setOpenDropdown(false);
    }

    return () => {
      if (searchSetTimeout) clearTimeout(searchSetTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (_, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  // If the user presses Enter, we want to search immediately
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      fetchData();
    }
  };

  // TODO : Analyse if we could replace this component with the DMS one
  return (
    <Autocomplete
      autoComplete={false}
      data-cr={dataCr}
      loading
      open={openDropdown}
      filterOptions={(autoCompleteOptions, _) => autoCompleteOptions}
      disablePortal
      freeSolo // Disables the popup 'No options' message
      forcePopupIcon={false}
      id="combo-box-demo"
      options={options}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      className="og-search-task-dropdown-sx"
      value={inputValue}
      clearIcon={false}
      disabled={searching}
      renderInput={(params) => (
        <TextField
          {...params}
          data-cr="dropdown-search-bar"
          InputProps={{
            ...params.InputProps,
            endAdornment: <SearchRoundedIcon />,
            className: "og-search-task-dropdown-sx__text-input",
          }}
          placeholder={placeholder}
        />
      )}
    />
  );
};

DropdownBlock.defaultProps = {
  dataCr: "search-bar",
  placeholder: "Search...",
};

export default DropdownBlock;
