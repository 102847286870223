import { capitalizeFirstLetter } from "compass-commons";
import React from "react";
import { Button } from "dms-lib";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";
import { OGExecutionTaskBlock } from "../../../../../model/OG/OGExecutionTaskBlock";

interface PerformActionProps {
  taskState: TaskStepState;
  blockType: OGExecutionTaskBlock;
  actionCallBack: (eventId: string) => void;
}

const PerformAction = (props: PerformActionProps) => {
  const { taskState, blockType, actionCallBack } = props;
  const eventId = blockType.button?.id;

  return (
    <div
      className={taskState}
      style={{ float: "right" }}
      data-cr="perform-action"
    >
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          actionCallBack(eventId);
        }}
        size="small"
        disabled={TaskStepState.COMPLETED === taskState}
      >
        <span>
          {blockType.button?.text
            ? capitalizeFirstLetter(blockType.button?.text)
            : ""}
        </span>
      </Button>
    </div>
  );
};

export default PerformAction;
