import * as React from "react";
import { useEffect, useState } from "react";
import "./formDialog.module.css";
import { Button, Dialog } from "dms-lib";

interface FormDialogProps {
  title?: JSX.Element;
  content?: JSX.Element;
  approvalText?: string;
  disapprovalText?: string;
  isDialogOpen?: boolean;
  dialogCallback: (open: boolean) => void;
  approvalConditionsMet?: boolean;
}

const FormDialog = (props: FormDialogProps): JSX.Element => {
  const {
    content,
    title = <></>,
    approvalText,
    disapprovalText,
    isDialogOpen,
    dialogCallback,
    approvalConditionsMet,
  } = props;
  const [isOpen, setIsOpen] = useState(isDialogOpen);

  useEffect(() => {
    setIsOpen(isDialogOpen);
  }, [isDialogOpen]);

  const handleClose = () => {
    setIsOpen(false);
    dialogCallback(false);
  };

  const handleConfirm = () => {
    setIsOpen(false);
    dialogCallback(true);
  };

  return (
    <Dialog
      open={isOpen}
      size="sm"
      dialogTitle={title}
      dialogContent={content}
      dialogActions={
        <>
          <Button
            dataCr="disapproval-button"
            color="primary"
            variant="outlined"
            onClick={handleClose}
          >
            {disapprovalText}
          </Button>
          <Button
            dataCr="confirmation-button"
            color="primary"
            variant="contained"
            className="form-dialog-confirm"
            disabled={!approvalConditionsMet}
            onClick={handleConfirm}
          >
            {approvalText}
          </Button>
        </>
      }
    />
  );
};

FormDialog.defaultProps = {
  title: "Confirmation Dialog",
  approvalText: "Yes",
  disapprovalText: "No",
  isDialogOpen: false,
  approvalConditionsMet: true,
};

export default FormDialog;
