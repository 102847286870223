import React, { PropsWithChildren } from "react";
import "./AssignedIncidentDialog.module.css";
import { Button, Dialog } from "dms-lib";
import { LocalizationNS, useI18n } from "compass-commons";
import { SocketAssignedIncidentDTO } from "../../model/incident/SocketAssignedIncident";
import { IncidentState } from "../../model/incident/IncidentState";
import { isCleared } from "../../helpers/incidentStateHelper";
import NewAssignedIncidentContent from "./components/NewAssignedIncidentContent";
import ClearedAssignedIncidentContent from "./components/ClearedAssignedIncidentContent";

interface AssignedIncidentDialogProps {
  isOpen: boolean;
  handleClose: (incidentState: IncidentState) => void;
  incident: SocketAssignedIncidentDTO;
}

const AssignedIncidentDialog = ({
  isOpen,
  handleClose,
  incident,
}: PropsWithChildren<AssignedIncidentDialogProps>): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <Dialog
      open={isOpen}
      dialogContent={
        <div
          className="assigned-incident-dialog"
          data-cr="assigned-incident-dialog"
        >
          <div className="assigned-incident-dialog-content">
            {incident &&
              (isCleared(incident.incidentState) ? (
                <ClearedAssignedIncidentContent />
              ) : (
                <NewAssignedIncidentContent incident={incident} />
              ))}
            <Button
              id="confirm-assignment-button"
              onClick={() => handleClose(incident?.incidentState)}
              color="primary"
              variant="contained"
              className="confirm-assignment-button"
            >
              {translate("ok", { ns: LocalizationNS.SHARED })}
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default AssignedIncidentDialog;
