// General
import React from "react";
import { useFeatureFlag } from "compass-commons";
import OperatorGuideActivationsHeader from "./OperatorGuideActivationsHeader";
// Utils
import { GROUPING_BY_ACTIVATION_FEATURE_FLAG } from "../../../../utils/constants";

interface OperatorGuideExecutionHeaderProps {
  additionalActivationsHeader: {
    isChecked: boolean;
    count: number;
  };
}
const OperatorGuideExecutionHeader = ({
  additionalActivationsHeader,
}: OperatorGuideExecutionHeaderProps): JSX.Element => {
  // FEATURE FLAGS
  const { enabled: groupingByActivationFeatureEnabled } = useFeatureFlag(
    appConfig,
    GROUPING_BY_ACTIVATION_FEATURE_FLAG
  );

  return (
    <div className="operator-guide-execution-header">
      {(groupingByActivationFeatureEnabled && (
        <OperatorGuideActivationsHeader
          count={additionalActivationsHeader.count}
          isChecked={additionalActivationsHeader.isChecked}
        />
      )) ||
        null}
    </div>
  );
};

export default OperatorGuideExecutionHeader;
