import { HttpClient } from "compass-shared-services";
import {
  FloorPlanDevice,
  FloorPlanImage,
  getImageMeta,
  getUrl,
  SiteFloorPlan,
} from "compass-commons";
import CommonIdentifier from "compass-commons/lib/esm/models/CommonIdentifier";
import { FloorPlanLightDTO } from "../model/Map/FloorPlanLightDTO";
import ImageMetaData from "../model/Map/ImageMetaData";

const { FLOOR_PLAN_MANAGER_PATH } = appConfig;
const httpClient = new HttpClient(appConfig);
const URL_PATHS = {
  GET_SITE_FLOOR_PLANS: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans`,
  GET_FLOOR_PLAN: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans/(floorPlanId)`,
  GET_FLOOR_PLAN_IMAGE: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans/(floorPlanId)/image`,
  GET_FLOOR_PLAN_BY_RESOURCE_ID: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/floor-plans?resourceMappingId=(resourceMappingId)`,
  GET_DEVICES_ON_MAP: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/georeferenced-map/devices`,
  GET_DEVICE_BY_RESOURCE_MAPPING_ID: `${FLOOR_PLAN_MANAGER_PATH}/sites/(siteId)/devices/(resourceMappingId)`,
};
/**
 * Map Service where backend service is called
 *
 */
export default class MapService {
  static async getSiteById(siteId: string): Promise<CommonIdentifier[]> {
    const uri = {
      siteId,
    };
    const urlPath = getUrl(URL_PATHS.GET_SITE_FLOOR_PLANS, uri);
    return httpClient
      .get<CommonIdentifier[]>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getFloorPlanWithMedia(
    siteId: string,
    floorPlanId: string
  ): Promise<{ site: SiteFloorPlan; imageMetaData: ImageMetaData }> {
    const floorPlan: SiteFloorPlan = await this.getFloorPlanById(
      siteId,
      floorPlanId
    );
    let imageMetaData: ImageMetaData = null;

    if (floorPlan) {
      if (!floorPlan.floorPlanImage) {
        floorPlan.floorPlanImage = {} as FloorPlanImage;
      }
      floorPlan.floorPlanImage.content = await this.getFloorPlanImage(
        siteId,
        floorPlanId
      );
      if (floorPlan.floorPlanImage?.content) {
        const floorUrl = URL.createObjectURL(floorPlan.floorPlanImage.content);
        const img = await getImageMeta(floorUrl);
        imageMetaData = {
          url: floorUrl,
          width: img.naturalWidth,
          height: img.naturalHeight,
        };
      }
    }
    return { site: floorPlan, imageMetaData };
  }

  static async getFloorPlanById(
    siteId: string,
    floorPlanId: string
  ): Promise<SiteFloorPlan> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.GET_FLOOR_PLAN, uri);
    return httpClient
      .get<SiteFloorPlan>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getFloorPlanImage(
    siteId: string,
    floorPlanId: string
  ): Promise<Blob> {
    const uri = {
      siteId,
      floorPlanId,
    };
    const urlPath = getUrl(URL_PATHS.GET_FLOOR_PLAN_IMAGE, uri);
    return httpClient
      .get<Blob>({
        url: urlPath,
        config: {
          responseType: "blob",
        },
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static getFloorPlanByDeviceId(
    siteId: string,
    resourceMappingId: string
  ): Promise<FloorPlanLightDTO[]> {
    const uri = {
      siteId,
      resourceMappingId,
    };
    const urlPath = getUrl(URL_PATHS.GET_FLOOR_PLAN_BY_RESOURCE_ID, uri);
    return httpClient
      .get<FloorPlanLightDTO[]>({ url: urlPath })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getGeoMappedDevices(siteId: string): Promise<FloorPlanDevice[]> {
    const uri = {
      siteId,
    };

    const urlPath = getUrl(URL_PATHS.GET_DEVICES_ON_MAP, uri);
    return httpClient
      .get<SiteFloorPlan>({
        url: urlPath,
      })
      .then((response) => {
        return response?.deviceList;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }

  static async getDeviceByResourceMappingId(
    siteId: string,
    resourceMappingId: string
  ): Promise<FloorPlanDevice> {
    const uri = {
      siteId,
      resourceMappingId,
    };

    const urlPath = getUrl(URL_PATHS.GET_DEVICE_BY_RESOURCE_MAPPING_ID, uri);
    return httpClient
      .get<FloorPlanDevice>({
        url: urlPath,
      })
      .then((response) => {
        return response;
      })
      .catch(async (error) => {
        throw Error(error);
      });
  }
}
