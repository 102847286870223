import React from "react";
import "./NewAssignedIncidentContent.scss";
import { useI18n } from "compass-commons";

const ClearedAssignedIncidentContent = (): JSX.Element => {
  const { t: translate } = useI18n();
  return (
    <>
      <p className="dms-type-hint">
        {translate("incident.clearedIncidentMovedResource")}
      </p>
    </>
  );
};

export default ClearedAssignedIncidentContent;
