import { capitalizeFirstLetter } from "compass-commons";
import React from "react";
import { Button } from "dms-lib";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";

interface MultiOptionActionProps {
  taskState: TaskStepState;
  actionCallBack: (eventId: string) => void;
  selectedOption: string;
}

const MultiOptionAction = (props: MultiOptionActionProps) => {
  const { taskState, selectedOption, actionCallBack } = props;
  const DEFAULT_BUTTON_TEXT = "Done";
  return (
    <div className={taskState} style={{ float: "right" }}>
      <Button
        color="primary"
        variant="contained"
        size="small"
        onClick={() => actionCallBack(null)}
        disabled={
          TaskStepState.COMPLETED === taskState ||
          (TaskStepState.CURRENT === taskState && !selectedOption)
        }
      >
        <span>{capitalizeFirstLetter(DEFAULT_BUTTON_TEXT)}</span>
      </Button>
    </div>
  );
};

export default MultiOptionAction;
