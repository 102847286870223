import React from "react";
import { RadioButton, RadioGroup } from "dms-lib";
import { OGExecutionTaskBlockLink } from "../../../../../model/OG/OGExecutionTaskBlock";
import { TaskStepState } from "../../../../../model/OG/TaskStepState";

interface MultiOptionTaskProps {
  blockId: any;
  options: OGExecutionTaskBlockLink[];
  selectedOption: string;
  onChangeCallback: (optId: string) => void;
  taskState: TaskStepState;
}

/**
 * MultiOptionTaskBlock component to display MultiOption Task
 * @param props
 * @constructor
 */
const MultiOptionTaskBlock = (props: MultiOptionTaskProps) => {
  const { blockId, options, selectedOption, onChangeCallback, taskState } =
    props;

  const getFontWeight = (op: OGExecutionTaskBlockLink) => {
    if (TaskStepState.COMPLETED === taskState && selectedOption) {
      if (selectedOption === op.id) {
        return 600;
      }
      return 400;
    }
    return "bold";
  };

  const getMultiOptionsRadio = () => {
    const opts = [];
    options.forEach((op: OGExecutionTaskBlockLink, index) => {
      opts.push(
        <RadioButton
          key={`radio-${index}-${op.id}`}
          disabled={TaskStepState.COMPLETED === taskState}
          value={op.id}
          label={op.text}
          style={{ fontWeight: getFontWeight(op) }}
        />
      );
    });
    return (
      <React.Fragment key={`multi-option-task-${blockId}-`}>
        {...opts}
      </React.Fragment>
    );
  };

  return (
    <div className="multiop-radio-group" data-cr="multi-options-task">
      <RadioGroup
        value={selectedOption}
        onChange={(value) => onChangeCallback(value)}
      >
        {getMultiOptionsRadio()}
      </RadioGroup>
    </div>
  );
};

export default MultiOptionTaskBlock;
