/* eslint-disable import/prefer-default-export */
import { ErrorCode } from "compass-widget-library";

const rmmServiceMapper = Object.freeze({
  602: "resources.noLongerInSite",
});

export const getErrorLabelFromRMMService = (errorCode: number): string => {
  return rmmServiceMapper?.[errorCode] || "resources.failInteraction";
};

const cameraWidgetMapper = Object.freeze({
  [ErrorCode.RESOURCE_NOT_FOUND]: "resources.noLongerInSite",
  [ErrorCode.GENERIC]: "resources.failOpenVideo",
});

export const getErrorLabelCameraWidget = (
  errorCode: ErrorCode
): string | undefined => {
  return cameraWidgetMapper?.[errorCode];
};
